
function PlaybookRow(props){
    return(
        <div className="playbook-row">
            <div className={`playbook-one ${props.last ? 'playbook-bottom': ''}`}>{props.data["Play"]}</div>
            <div className={`playbook-two ${props.last ? 'playbook-bottom': ''}`}>{props.data["Trigger"]}</div>
            <div className={`playbook-three ${props.last ? 'playbook-bottom': ''}`}>{props.data["Owner"]}</div>
            <div className={`playbook-four ${props.last ? 'playbook-bottom': ''}`}>{props.data["CTA"]}</div>
            <div className={`playbook-five ${props.last ? 'playbook-bottom': ''}`}>{props.data["Collateral"]}</div>
        </div>
    );
}

export default PlaybookRow;