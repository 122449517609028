import { Tooltip } from "@chakra-ui/react"; //using chakra library tooltip (https://v2.chakra-ui.com/docs/components/tooltip/usage)
import { CiCircleInfo } from "react-icons/ci";
import "../styles/InfoTooltip.css";

function InfoTooltip(props) {
  return (
    <div className={props.className}>
      <Tooltip
        hasArrow
        label={props.content}
        fontSize="sm"
        placement="bottom-end"
        bg="white"
        color="black"
      >
        <span>
          <CiCircleInfo className="info-icon" color="white" size={30} />
        </span>
      </Tooltip>
      </div>

  );
}

export default InfoTooltip;
