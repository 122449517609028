import React from "react";
import { useState } from "react";

import { VscSend } from "react-icons/vsc";
import { waveform } from "ldrs";
import { CiCircleInfo } from "react-icons/ci";
import InfoTooltip from "../ui/InfoTooltip";
import { MixPanelEvents, Mixpanel } from "../../lib/mix-panel-helpers";

function Email(props) {
  const [responseState, setResponseState] = useState(0); //0 for no submittion, 1 for submitted but loading, 2 for data returned, 3 for error this is for the loading animation
  const [receivedDataEmail, setReceivedDataEmail] = useState(null);
  waveform.register(); //need to register for loading animation

  const handleGenerateEmail = () => {
    if (props.isLoggedIn === 0) {
      props.setIsLoggedIn(1);
    }

    const recipient = document.getElementById("recipient").value;
    const purpose = document.getElementById("purpose").value;
    const context = document.getElementById("context").value;

    const emailContent = {
      recipient,
      purpose,
      context,
    };

    const jsonData = JSON.stringify(emailContent);

    if (props.isLoggedIn === 2) {
      console.log("Generating Email");
      setResponseState(1);
      setReceivedDataEmail(null);
      generateEmail(jsonData);
    }
  };

  const generateEmail = (jsonData) => {
    Mixpanel.track(MixPanelEvents.FEATURE_USE, {
      feature: "Email",
    });
    const apiUrl = process.env.REACT_APP_API_URL_EMAIL;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setReceivedDataEmail(data);
        setResponseState(2);
      })
      .catch((error) => {
        console.error("Error:", error);
        setResponseState(3);
      });
  };

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <InfoTooltip
        className="customer-persona-tooltip"
        content="Create personalized and effective emails to communicate with clients"
      />
      <div className="container">
        <div className="input-container">
          <h1 className="gray-text">Recipient (name and company)</h1>
          <input
            id="recipient"
            className="small-info"
            type="text"
            placeholder="John Doe, ACME Co."
          />
          <h1 className="gray-text">Purpose of email</h1>
          <textarea
            id="purpose"
            className="small-info"
            type="text"
            placeholder="Inquiring about product usage"
          />
          <h1 className="gray-text">
            Any specific information about your customer and the situation
          </h1>
          <textarea
            id="context"
            className="large-info"
            type="text"
            placeholder="At Kramer Industries, we will be..."
          />
        </div>
        <div className="empty-container">
          <h1 className="empty-label-text">Magic will appear here...</h1>
          <div className="empty-text-box-email">
            <div
              className={`${
                responseState === 1 || responseState === 3
                  ? "container-loading"
                  : "generated-text"
              }`}
              style={{ height: "100%" }}
            >
              {receivedDataEmail && (
                <React.Fragment>
                  <h1 className="subject">
                    {receivedDataEmail.response.split("\n")[0]}
                  </h1>
                  {receivedDataEmail.response
                    .split("\n")
                    .slice(1)
                    .map((line, index) => (
                      <h1 key={index}>{line}</h1>
                    ))}
                </React.Fragment>
              )}

              {responseState === 1 && ( // Default values shown
                <div>
                  <l-waveform size="35" stroke="3.5" speed="1" color="white" />
                </div>
              )}

              {responseState === 3 && (
                <h1>There was an error, please try again...</h1>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="white-button-container">
        <button className="generate-button" onClick={handleGenerateEmail}>
          Generate Email <VscSend />
        </button>
      </div>
    </div>
  );
}

export default Email;
