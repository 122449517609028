
function PlaybookRowTitle(){
    return(
        <div className="playbook-row">
            <div className='playbook-one'>Play</div>
            <div className='playbook-two'>Triggers</div>
            <div className='playbook-three'>Owner</div>
            <div className='playbook-four'>CTA</div>
            <div className='playbook-five'>Collateral</div>
        </div>
    );
}

export default PlaybookRowTitle;