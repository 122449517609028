import "../styles/Suggestions.css";

function SuggestionsModalInterior(props) {
  return (
    <div className="suggestions-modal-interior">
      <div className="suggestions-modal-header">
        Thank you for helping improve Eesa Lite!
      </div>
      <textarea
        placeholder="Request a use case, feature options, etc..."
        className="suggestions-modal-textarea"
      ></textarea>
      <button className="suggestions-button">Submit Feedback</button>
    </div>
  );
}

export default SuggestionsModalInterior;
