import mixpanel from "mixpanel-browser";

mixpanel.init(process.env.REACT_APP_MIX_PANEL_PROJECT_TOKEN, {
  debug: true,
  track_pageview: true,
});

let env_check = true;

let actions = {
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) {
      mixpanel.track(name, props);
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export const MixPanelEvents = {
  SIGNUP: "Sign Up",
  LOGIN: "Login",
  FEATURE_VISIT: "Feature Visit",
  FEATURE_USE: "Feature Use",
  VISIT: "Visit",
};

export let Mixpanel = actions;
