import React from "react";
import { useState } from "react";

import { VscSend } from "react-icons/vsc";
import { waveform } from "ldrs";
import { CiCircleInfo } from "react-icons/ci";
import InfoTooltip from "../ui/InfoTooltip";
import { MixPanelEvents, Mixpanel } from "../../lib/mix-panel-helpers";

function CustomerPersona(props) {
  const [responseState, setResponseState] = useState(0); //0 for no submittion, 1 for submitted but loading, 2 for data returned, 3 for error this is for the loading animation
  const [receivedDataCustomer, setReceivedDataCustomer] = useState(null);

  const handleGenerateCustomer = () => {
    if (props.isLoggedIn === 0) {
      props.setIsLoggedIn(1);
    }

    const customer_job_title =
      document.getElementById("customer-job-title").value;
    const customer_goals = document.getElementById("customer-goals").value;
    const customer_pain_points = document.getElementById(
      "customer-pain-points"
    ).value;
    const customer_success_metrics = document.getElementById(
      "customer-success-metrics"
    ).value;
    const context = document.getElementById("customer-context").value;

    const customerContent = {
      customer_job_title,
      customer_goals,
      customer_pain_points,
      customer_success_metrics,
      context,
    };

    const jsonData = JSON.stringify(customerContent);

    if (props.isLoggedIn === 2) {
      console.log("Generating Customer Persona");
      setResponseState(1);
      setReceivedDataCustomer(null);
      generateCustomer(jsonData);
    }
  };

  const generateCustomer = (jsonData) => {
    Mixpanel.track(MixPanelEvents.FEATURE_USE, {
      feature: "Customer Persona",
    });

    const apiUrl = process.env.REACT_APP_API_URL_CUSTOMER;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setReceivedDataCustomer(data);
        setResponseState(2);
      })
      .catch((error) => {
        console.error("Error:", error);
        setResponseState(3);
      });
  };
  const [isHovered, setIsHovered] = useState(false);

  waveform.register(); //need to register for loading animation
  return (
    <div>
      <InfoTooltip
        className="customer-persona-tooltip"
        content="Create a representative customer persona to help you focus on the needs of your most important customers and strategize on delivering maximum value to them"
      />
      <div className="container">
        <div className="input-container">
          <h1 className="gray-text">Customer Job Title</h1>
          <input
            id="customer-job-title"
            className="small-info"
            type="text"
            placeholder="Example"
          />
          <h1 className="gray-text">Customer Goals</h1>
          <textarea
            id="customer-goals"
            className="large-info"
            type="text"
            placeholder="ACME Co's main objectives with us are to increase..."
          />
          <h1 className="gray-text">Customer Pain Points</h1>
          <textarea
            id="customer-pain-points"
            className="large-info"
            type="text"
            placeholder="ACME Co's current pain points are..."
          />
          <h1 className="gray-text">Customer Success Metrics</h1>
          <textarea
            id="customer-success-metrics"
            className="large-info"
            type="text"
            placeholder="Success with our product for ACME Co is if they..."
          />
          <h1 className="gray-text">
            Your company background and any other relevant info
          </h1>
          <textarea
            id="customer-context"
            className="large-info"
            type="text"
            placeholder="My company, Kramer Industries, provides..."
          />
        </div>
        <div className="empty-container">
          <h1 className="empty-label-text">Magic will appear here...</h1>
          <div className="empty-text-box">
            <div
              className={`${
                responseState === 1 || responseState === 3
                  ? "container-loading"
                  : "generated-text-action"
              }`}
              style={{ height: "100%" }}
            >
              {receivedDataCustomer && (
                <React.Fragment>
                  <h1 className="subject">
                    {receivedDataCustomer.response.split("\n")[0]}
                  </h1>
                  {receivedDataCustomer.response
                    .split("\n")
                    .slice(1)
                    .map((line, index) => (
                      <h1 key={index}>{line}</h1>
                    ))}
                </React.Fragment>
              )}

              {responseState === 1 && ( // Default values shown
                <div>
                  <l-waveform size="35" stroke="3.5" speed="1" color="white" />
                </div>
              )}

              {responseState === 3 && (
                <h1>There was an error, please try again...</h1>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="white-button-container">
        <button className="generate-button" onClick={handleGenerateCustomer}>
          Generate Customer Persona <VscSend />
        </button>
      </div>
    </div>
  );
}

export default CustomerPersona;
