import React from "react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { VscSend } from "react-icons/vsc";
import { CiCircleInfo } from "react-icons/ci";
import { waveform } from "ldrs";
import PlaybookRowTitle from "./PlaybookRowTitle.js";
import PlaybookRow from "./PlaybookRow.js";

import InfoTooltip from "../ui/InfoTooltip.js";
import { MixPanelEvents, Mixpanel } from "../../lib/mix-panel-helpers.js";

function Playbook(props) {
  waveform.register(); //need to register for loading animation

  const initalFormData = {
    playbook_type: "onboarding",
    company_info: "",
    client_info: "",
    context: "",
  };

  const [isOpen, setIsOpen] = useState(false);
  const [playbookType, setPlaybookType] = useState("Onboarding");
  const [playbookForm, setPlaybookForm] = useState(initalFormData);
  const [playbookResponse, setPlaybookResponse] = useState([[]]);
  const [responseState, setResponseState] = useState(0); //0 for no submittion, 1 for submitted but loading, 2 for data returned, 3 for error

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (e) => {
    setPlaybookType(e.target.innerText);
    if (e.target.innerText === "Onboarding") {
      setPlaybookForm({
        ...playbookForm,
        ["playbook_type"]: "onboarding",
      });
    } else if (e.target.innerText === "Churn Prevention") {
      setPlaybookForm({
        ...playbookForm,
        ["playbook_type"]: "churn_prevention",
      });
    } else if (e.target.innerText === "Outreach") {
      setPlaybookForm({
        ...playbookForm,
        ["playbook_type"]: "outreach",
      });
    }
    toggleDropdown();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPlaybookForm({
      ...playbookForm,
      [name]: value,
    });
  };

  const postPlaybook = async () => {
    const url = process.env.REACT_APP_API_URL_PLAYBOOK;
    const param = {
      method: "POST",
      body: JSON.stringify(playbookForm),
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    };
    const request = await fetch(url, param);
    const response = await request.json();
    return response;
  };

  const handleSubmit = () => {
    if (props.isLoggedIn === 0) {
      props.setIsLoggedIn(1);
    } else {
      setResponseState(1);
      Mixpanel.track(MixPanelEvents.FEATURE_USE, {
        feature: "Playbook",
      });
      postPlaybook()
        .then((data) => {
          //convert to an array of json for easier passthrough
          var result = [];
          var keys = Object.keys(data.response);
          keys.forEach(function (key) {
            result.push(data.response[key]);
          });
          setPlaybookResponse(result);
          setResponseState(2);
        })
        .catch((error) => {
          setResponseState(3);
          console.log(error);
        });
    }
  };

  return (
    <div>
      <div className="playbook-container">
        <InfoTooltip content="Create tailored playbooks for addressing specific scenarios to optimize client retention and satisfaction" />
        <div className="playbook-type-text">Playbook type</div>
        <button onClick={toggleDropdown} className="playbook-type-dropdown">
          {playbookType} <FaChevronDown />
        </button>
        <div>
          {isOpen && (
            <div className="playbook-type-dropdown-options">
              <ul>
                <li name="playbook_type" onClick={handleClick}>
                  Onboarding
                </li>
                <li name="playbook_type" onClick={handleClick}>
                  Churn Prevention
                </li>
                <li name="playbook_type" onClick={handleClick}>
                  Outreach
                </li>
              </ul>
            </div>
          )}
        </div>

        <h1 className="gray-text">Tell us about your company</h1>
        <textarea
          name="company_info"
          id="customer-job-title"
          className="small-info"
          type="text"
          placeholder="B2B or B2C? What service do you provide? How is it used?"
          onChange={handleChange}
        />

        <h1 className="gray-text">Tell us about your client</h1>
        <textarea
          name="client_info"
          id="customer-job-title"
          className="small-info"
          type="text"
          placeholder="What service do they provide? How do they use your service? Who is your main point of contact?"
          onChange={handleChange}
        />

        <h1 className="gray-text">
          Context of the scenario you are trying to build a playbook for
        </h1>
        <textarea
          name="context"
          id="customer-job-title"
          className="small-info"
          type="text"
          placeholder="ACME Inc's latest payment to us failed. We are not sure whether..."
          onChange={handleChange}
        />

        <div className="playbook-result-container">
          {(responseState === 0 || responseState === 1) && (
            <p className="playbook-magic-text">Magic will appear here...</p>
          )}

          <div
            className={`playbook-result-box ${
              responseState === 1 || responseState === 3
                ? "container-loading"
                : ""
            }`}
          >
            {responseState === 1 && ( // Default values shown
              <l-waveform size="35" stroke="3.5" speed="1" color="white" />
            )}

            {responseState === 2 && (
              <React.Fragment>
                <div className="playbook-result-box-title">{`${playbookType} Playbook`}</div>
                <div className="playbook-result-spreadsheet">
                  <PlaybookRowTitle />
                  {[...Array(playbookResponse.length)].map((x, i) => (
                    <PlaybookRow
                      data={playbookResponse[i]}
                      last={i === playbookResponse.length - 1 ? true : false}
                    />
                  ))}
                </div>
              </React.Fragment>
            )}

            {responseState === 3 && (
              <h1>There was an error, please try again...</h1>
            )}
          </div>
        </div>

        <div className="white-button-container">
          <button className="generate-button" onClick={handleSubmit}>
            Generate Playbook <VscSend />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Playbook;
