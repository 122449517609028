import { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { FaUpload, FaChevronDown, FaCaretRight, FaTrash } from "react-icons/fa";
import React from "react";
import Main from "./components/main";
import Logout from "./components/logout";
import { gapi } from "gapi-script";
// import PrivateRoute from './components/privateroute'
import { Navigate } from "react-router-dom";
import { Router, Route, Routes } from "react-router";
// import { Switch} from 'react-rou"896689388187-6dgohm3imna2gnhbkikgs5otllggi9on.apps.googleusercontent.com"ter-dom';
import dotenv from "dotenv";
import dotenvExpand from "dotenv-expand";

const env = dotenv.config(); // This loads the environment variables from .env into process.env
dotenvExpand.expand(env); // Expands variables set in .env file

const clientId = process.env.REACT_APP_CLIENT_ID;
import { MixPanelEvents, Mixpanel } from "./lib/mix-panel-helpers";

export default function App() {
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
    Mixpanel.track(MixPanelEvents.VISIT);
  });

  return (
    <div className="App">
      <Main />
    </div>
  );
}
