import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./styles.css";
import App from "./App";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const root = createRoot(document.getElementById("root"));

const theme = extendTheme({
  //this is to get rid of the default styling that chakra uses
  styles: {
    global: () => ({
      body: {
        background: "",
      },
    }),
  },
});

// Mix Pannel Configs:
import mixpanel from "mixpanel-browser";
const mixpanelProjectToken = process.env.MIX_PANEL_PROJECT_TOKEN;
mixpanel.init(mixpanelProjectToken, {
  debug: true,
  track_pageview: true,
  persistence: "localStorage",
});

root.render(
  <ChakraProvider theme={theme} resetCSS={false}>
    <StrictMode>
      <App />
    </StrictMode>
  </ChakraProvider>
);
