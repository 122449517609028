import React from "react";
import { useState } from "react";
import { VscSend } from "react-icons/vsc";
import { FaTrash } from "react-icons/fa";
import { waveform } from "ldrs";
import { CiCircleInfo } from "react-icons/ci";
import InfoTooltip from "../ui/InfoTooltip";
import { MixPanelEvents, Mixpanel } from "../../lib/mix-panel-helpers";

function ActionItems(props) {
  const [receivedDataAction, setReceivedDataAction] = useState(null);
  const [data, setData] = useState([{ fname: "", lname: "" }]);
  const [errorMsg, setErrorMsg] = useState("");
  const [responseState, setResponseState] = useState(0); //0 for no submittion, 1 for submitted but loading, 2 for data returned, 3 for error this is for the loading animation

  const textareaHeight = 300;
  const containerHeight = data.length * textareaHeight + 25 * (data.length - 1);

  waveform.register(); //need to register for loading animation

  const handleClickAdd = () => {
    if (data.length < 3) {
      setData((prevData) => [...prevData, { message: "" }]);
      setErrorMsg("");
    } else {
      setErrorMsg("Only 3 boxes for meeting/emails can be added max");
    }
  };

  const handleClickDelete = () => {
    if (data.length > 1) {
      setData((prevData) => prevData.slice(0, -1));
      setErrorMsg("");
    } else {
      setErrorMsg("At least 1 box for meeting/emails is required minimum");
    }
  };

  const handleGenerateAction = () => {
    if (props.isLoggedIn === 0) {
      props.setIsLoggedIn(1);
    }

    setErrorMsg("");

    const str1 = document.getElementById("action-0").value;
    var transcripts = [str1];
    if (data.length > 1) {
      const str2 = document.getElementById("action-1").value;
      transcripts.push(str2);
    }
    if (data.length > 2) {
      const str3 = document.getElementById("action-2").value;
      transcripts.push(str3);
    }

    const actionContent = {
      transcripts,
    };

    const jsonData = JSON.stringify(actionContent);

    if (props.isLoggedIn === 2) {
      console.log("Generating Actions");
      setReceivedDataAction(null);
      setResponseState(1); //loading
      generateAction(jsonData);
    }
  };

  const generateAction = (jsonData) => {
    Mixpanel.track(MixPanelEvents.FEATURE_USE, {
      feature: "Action Items",
    });
    const apiUrl = process.env.REACT_APP_API_URL_ACTION;
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setReceivedDataAction(data);
        setResponseState(2);
      })
      .catch((error) => {
        console.error("Error:", error);
        setResponseState(3);
      });
  };
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <InfoTooltip
        className="customer-persona-tooltip"
        content="Create a list of action items and deadlines from meeting transcripts, emails, etc"
      />
      <div className="container">
        <div className="input-container">
          <h1 className="gray-text">
            Paste a message, meeting transcript, or email
          </h1>
          {data.map((val, i) => (
            <textarea
              id={`action-${i}`}
              className="large-info-action"
              type="text"
              placeholder="Hello Mr. Doe..."
            />
          ))}
        </div>
        <div className="empty-container">
          <h1 className="empty-label-text">Magic will appear here...</h1>
          <div className="empty-text-box" style={{ height: containerHeight }}>
            {
              <div
                className={`${
                  responseState === 1 || responseState === 3
                    ? "container-loading"
                    : "generated-text-action"
                }`}
                style={{ height: "100%" }}
              >
                {receivedDataAction && (
                  <React.Fragment>
                    <h1 className="subject">
                      {receivedDataAction.response.split("\n")[0]}
                    </h1>
                    {receivedDataAction.response
                      .split("\n")
                      .slice(1)
                      .map((line, index) => (
                        <h1 key={index}>{line}</h1>
                      ))}
                  </React.Fragment>
                )}

                {responseState === 1 && ( // Default values shown
                  <div>
                    <l-waveform
                      size="35"
                      stroke="3.5"
                      speed="1"
                      color="white"
                    />
                  </div>
                )}

                {responseState === 3 && (
                  <h1>There was an error, please try again...</h1>
                )}
              </div>
            }
          </div>
        </div>
      </div>
      <div>
        <button className="action-item-button" onClick={handleClickAdd}>
          <span className="plus">+</span> add meeting/email
        </button>
        <button className="action-item-button" onClick={handleClickDelete}>
          <span className="trash">
            <FaTrash />
          </span>{" "}
          delete meeting/email
        </button>
      </div>
      {errorMsg && <p className="error-message">{errorMsg}</p>}
      <div className="white-button-container">
        <button className="generate-button" onClick={handleGenerateAction}>
          Generate Action Items <VscSend />
        </button>
      </div>
    </div>
  );
}

export default ActionItems;
