import '../styles/Suggestions.css';

function Suggestions(props){
    function handleClick(){
        const url = "https://tally.so/r/nr6Ny2";
        window.open(url, "_blank") //to open new page
    }

    return(
        <div className='suggestions-box'>
            <div className="suggestions-text">Suggestions?</div>
            <button className='suggestions-button' onClick={handleClick}>Give us Feedback</button>
        </div>
    );
}

export default Suggestions;