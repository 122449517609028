import React from "react";
import { useState } from "react";
import BackgroundImage from "../images/eesa-logo.png";
import { waveform } from "ldrs";
import Modal from "react-modal";

import Playbook from "./tabs/Playbook.js";
import CustomerPersona from "./tabs/CustomerPersona.js";
import Email from "./tabs/Email.js";
import ActionItems from "./tabs/ActionItems.js";

import Suggestions from "./ui/Suggestions.js";
import SuggestionsModalInterior from "./ui/SuggestionsModalInterior.js";

import BigLogo from "./../images/eesa-logo-big.png";
import axios from "axios";
import { MixPanelEvents, Mixpanel } from "../lib/mix-panel-helpers.js";

// const clientId = process.env.REACT_APP_CLIENT_ID;

export default function Main() {
  const [suggestionsModalIsOpen, setSuggestionsModalIsOpen] = useState(false);

  function openSuggestionsModal() {
    setSuggestionsModalIsOpen(true);
  }

  function closeSuggestionsModal() {
    setSuggestionsModalIsOpen(false);
  }

  waveform.register(); //need to register for loading animation
  const [responseState, setResponseState] = useState(0); //0 for no submittion, 1 for submitted but loading, 2 for data returned, 3 for error this is for the loading animation

  const [isLoggedIn, setIsLoggedIn] = useState(0); //-1 is show signup modal, 0 is do not show modal, 1 is show modal for login

  const onSuccess = (res) => {
    console.log("LOGIN SUCCESS! Current user: ", res.profileObj);
    setIsLoggedIn(2);
    // generate(generateFunction, generateInput);
  };
  const onFailure = (res) => {
    console.log("LOGIN failed! res ", res);
  };

  const [selectedButton, setSelectedButton] = useState(0);
  const features = ["Playbook", "Customer Persona", "Email", "Action Items"];
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    setIsDropdownOpen(!isDropdownOpen);
    setResponseState(0);

    Mixpanel.track(MixPanelEvents.FEATURE_VISIT, {
      feature: features[buttonIndex],
    });
  };

  const generate = (func, inp) => {
    if (func != null && inp != null) {
      func(inp);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (event) => {
    if (!event.target.matches(".dropbtn")) {
      setIsOpen(false);
    }
  };

  // TEST:
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    // validateSignUpData()

    // perform sign up
    const data = {
      email: userEmail,
      password: userPassword,
    };

    console.log(data);

    axios
      .post(process.env.REACT_APP_API_SIGNUP, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        console.log("SIGNUP: ", response.data);
        Mixpanel.track(MixPanelEvents.SIGNUP, {
          email: response.data.email,
        });
        setIsLoggedIn(2);
        // generate(generateFunction, generateInput);
        handleLogin(e);
        setErrorMessage([]);
      })
      .catch(function (error) {
        var em = [];
        console.log(error.response);
        if (error.response) {
          if (error.response.data.email != undefined) {
            for (let i = 0; i < error.response.data.email.length; i++) {
              em = em.concat(error.response.data.email[i]);
            }
          }
          if (error.response.data.password != undefined) {
            for (let i = 0; i < error.response.data.password.length; i++) {
              em = em.concat(error.response.data.password[i]);
            }
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(em);
        setErrorMessage(em);
      });
  };
  // LOGIN
  const handleLogin = async (e) => {
    e.preventDefault();
    // validateLoginData()

    // perform login
    const data = {
      email: userEmail,
      password: userPassword,
    };

    axios
      .post(process.env.REACT_APP_API_LOGIN, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        const token = response.data.auth_token;
        console.log("LOGIN: ", userEmail);
        localStorage.setItem("token", token);

        Mixpanel.track(MixPanelEvents.LOGIN, { email: userEmail });
        axios.defaults.headers.post[
          "Authorization"
        ] = `Token ${localStorage.getItem("access_token")}`;
        onSuccess(response);
      })
      .catch(function (error) {
        var em = [];
        if (error.response) {
          if (error.response.data.non_field_errors != undefined) {
            em = em.concat(error.response.data.non_field_errors);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        setErrorMessage(em);
      });
  };
  const formatErrorMessage = () => {
    const formattedErrorMessage = errorMessage.map((message) => (
      <li>{message}</li>
    ));
    return <ul>{formattedErrorMessage}</ul>;
  };

  return (
    <>
      {isLoggedIn === 1 && (
        <div className="shade">
          <div className="box">
            <img src={BackgroundImage} alt="Logo" className="eesa-logo" />
            <h2>Login</h2>
            <form onSubmit={handleLogin}>
              <div className="inputbox">
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="Email Address"
                  required
                />
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
              </div>
              <input type="submit" name="" value="Continue" />
              <p className="no-account">
                {" "}
                Don't have an account?{" "}
                <span>
                  {" "}
                  <button
                    className="sign-up-button"
                    onClick={() => {
                      setIsLoggedIn(-1);
                    }}
                  >
                    Sign Up
                  </button>
                </span>
                {formatErrorMessage()}
              </p>

              {/*
              <div className="line">
                <div className="or">OR</div>
              </div>
              <div className="icon-span">
                <GoogleLogin
                  className="g-logo"
                  clientId={clientId}
                  render={(renderProps) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%", // Adjust the width as needed
                        height: "100%", // Adjust the height as needed
                        backgroundColor: "white", // Set the background color to white
                        borderRadius: "50%", // Optional: Add rounded corners for circular shape
                      }}
                    >
                      <img
                        src={Google}
                        alt="Google Icon"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                        style={{
                          cursor: "pointer", // Show pointer cursor
                          width: "50px",
                          height: "50px",
                          backgroundColor: "white",
                          borderRadius: "50%",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  )}
                  buttonText=""
                  onSuccess={onSuccess}
                  onFailure={onFailure}
                  cookiePolicy={"single_host_origin"}
                  isSignedIn={false} // Set isSignedIn to false initially
                />

              </div>

                      */}
            </form>
          </div>
        </div>
      )}

      {isLoggedIn === -1 && (
        <div className="shade">
          <div className="box">
            <img src={BackgroundImage} alt="Logo" className="eesa-logo" />
            <h2>Sign Up</h2>
            <form onSubmit={handleSignUp}>
              <div className="inputbox">
                <input
                  type="text"
                  name="email"
                  id="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                  placeholder="Email Address"
                  required
                />
                <input
                  type="text"
                  name="password"
                  id="password"
                  value={userPassword}
                  onChange={(e) => setUserPassword(e.target.value)}
                  placeholder="Password"
                  required
                />
              </div>
              <input type="submit" name="" value="Continue" />
              <p className="no-account">
                {" "}
                Already have an account?{" "}
                <span>
                  <button
                    className="sign-up-button"
                    onClick={() => {
                      setIsLoggedIn(1);
                    }}
                  >
                    Login
                  </button>
                </span>
                {formatErrorMessage()}
              </p>
            </form>
          </div>
        </div>
      )}

      <div className="App">
        <header className="App-header">
          <nav class="navbar">
            <div class="logo">
              <img src={BigLogo} alt="Logo" className="logo" />
            </div>
            <div class="nav-links">
              <a href="https://www.eesa.ai/">
                <button class="nav-button home">Home</button>
              </a>
              <a href="https://calendly.com/keerti_jeeva/30min">
                <button class="nav-button try-for-free">Book a Demo</button>
              </a>
            </div>
          </nav>
          <h1 className="header-text">AI Tools for CSMs</h1>
          <h1 className="subtitle-text">
            Eesa Lite helps 10x your productivity. What would you like to create
            today?
          </h1>
          <div className="button-and-content-container">
            <div className="button-container">
              <button
                className={selectedButton === 0 ? "selected" : ""}
                onClick={() => handleButtonClick(0)}
              >
                Playbook
              </button>
              <button
                className={selectedButton === 1 ? "selected" : ""}
                onClick={() => handleButtonClick(1)}
              >
                Customer Persona
              </button>

              <button
                className={selectedButton === 2 ? "selected" : ""}
                onClick={() => handleButtonClick(2)}
              >
                Email
              </button>
              <button
                className={selectedButton === 3 ? "selected" : ""}
                onClick={() => handleButtonClick(3)}
              >
                Action Items
              </button>
            </div>
            {selectedButton === 0 /* If Playbook is selected */ && (
              <Playbook isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            )}

            {selectedButton === 1 && ( //If customer persona is selected
              <CustomerPersona
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            )}

            {selectedButton === 2 /* If Email is selected */ && (
              <Email isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            )}

            {selectedButton === 3 /* If action item is selected */ && (
              <ActionItems
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            )}
          </div>
        </header>

        <Suggestions openSuggestionsModal={openSuggestionsModal} />

        <Modal
          isOpen={suggestionsModalIsOpen}
          onRequestClose={closeSuggestionsModal}
          contentLabel="suggestions box"
          className="suggestions-modal"
          overlayClassName="suggestions-modal-overlay"
        >
          <SuggestionsModalInterior />
        </Modal>
      </div>
    </>
  );
}
